<template>
  <div id="trainingCoursesDetail" v-wechat-title='title'>
    <CommonBread></CommonBread>
    <div class="common_layout">
      <div class="layout_left"> 
        <div class="panel_one">
          <img :src="baseUrl + detail.coverUrl" />
          <div>
            <h2>{{ detail.courseName }}</h2>
            <div class="price">价格 <span><span>￥</span>{{ detail.coursePrice }} /人</span></div>
            <CommonFormInfoStatic :detail="detail" :dataList="dataList"></CommonFormInfoStatic>
            <div class="button">
              <a-button @click="applyClick" type="primary">立即报名</a-button>
              <a-button @click="visible = true; detail = detail">联系我们</a-button>
            </div>
          </div>
        </div>
        <div v-if="detail.organization" class="panel_two" @click="jiClick">
          <img :src="baseUrl + detail.organization.logoUrl" />
          <div>
            <h3>{{ detail.organization.organizationName }}</h3>
            <p>{{ detail.organization.briefIntroduction }}</p>
          </div>
        </div>
        <div class="panel_three w-e-text" v-html="detail.context"></div>
      </div>
      <div class="layout_right">
        <CourseTeacher title="课程讲师" :teacherList="detail.teacherList || []"></CourseTeacher>
        <CommonMenu v-if="detail.cateList && detail.cateList.length" :headList="detail.cateList"></CommonMenu>
      </div>
    </div>
    <RelationModal :visible="visible" :detail="detail" @close="visible = false;"></RelationModal>
  </div>
</template>

<script>
import CommonBread from '@/components/CommonBread.vue'
import CommonMenu from '@/components/CommonMenu.vue'
import CommonFormInfoStatic from '@/components/CommonFormInfoStatic.vue'
import CourseTeacher from './components/CourseTeacher.vue'
import RelationModal from './components/RelationModal.vue'
export default {
  components: {
    CommonBread,
    CommonFormInfoStatic,
    CommonMenu,
    CourseTeacher,
    RelationModal
  },
  data() {
    return {
      visible: false,
      detail: {},
      dataList: [
        {
          title: '',
          flex: 1,
          children: [
            { icon: 'sort', name: '课程分类', key: 'courseSort' },
            { icon: 'artboard', name: '课程形式', key: 'courseFormat' },
            // { icon: 'artboard', name: '课程形式', key: 'courseFormat', tip: '（对不方便到现场的学员提供线上直播）' },
            { icon: 'location', name: '上课地点', key: 'classLocation' },
            { icon: 'calendar', name: '课程时间', key: ['courseStart', 'courseEnd'], type: 2 },
            { icon: 'quota', name: '名额数量', key: 'numberPlaces', tip: '人' },
            { icon: 'time', name: '报名截止时间', key: 'applyEnd' },
          ]
        },
      ],
      title: '广东省碳达峰碳中和标准化技术信息公共服务平台'
    }
  },
  mounted() {
    this.showPage()
  },
  methods: {
    jiClick() {
      window.open(`/institutionDetail/${this.detail.organizationId}`)
    },
    showPage() {
      this.$API.TrainingCoursesDetail({
        id: parseInt(this.$route.params.id)
      }).then(res => {
        if(res.data.code == 200) {
          this.detail = res.data.data
          this.title = `${res.data.data.courseName} - 广东省碳达峰碳中和标准化技术信息公共服务平台`
        }else {
          this.$router.replace('/404')
        }
      })
    },
    applyClick() {
      if(!this.$store.state.loginIn) return this.$store.commit('open_type', 1)
      window.open(this.detail.applyLink)
    }
  }
}
</script>

<style lang='scss'>
  #trainingCoursesDetail {
    .common_layout {
      padding-top: 20px;
    }

    .layout_left {
      padding-left: 10px;
    }

    .common_menu {
      margin-top: 10px;
    }

    .panel_one {
      display: flex;

      & > img {
        width: 270px;
        height: 180px;
        margin-right: 20px;
      }

      & > div {
        &> h2 {
          font-size: 32px;
          color: #303133;
          padding: 0 10px 15px;
          line-height: 1.5;
        }

        .price {
          background: #fafafa;
          padding: 15px 10px;
          font-size: 14px;
          color: #606266;
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          font-weight: 600;

          & > span {
            margin-left: 20px;
            font-size: 22px;
            color: #ff8139;
            line-height: 30px;

            & > span {
              font-size: 16px;
              margin-right: 5px;
            }
          }
        }
      }
      
    }

    .panel_two {
      background: #fafafa;
      padding: 30px;
      margin-top: 50px;
      display: flex;
      cursor: pointer;
      margin-bottom: 20px;

      &:hover {
        background: #F2F2F2;
      }

      & > img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin-right: 20px;
      }

      & >div {
        & > h3 {
          font-size: 18px;
          color: #303133;
          line-height: 28px;
          margin-bottom: 10px;
        }

        & > p {
          font-size: 14px;
          color: #606266;
          line-height: 24px;

        }
      }
    }

    .arco-descriptions-row {
      display: flex;
      align-items: center;
    }

    .arco-descriptions-item-label-block {
      margin: 0;
      padding: 0;
      color: #606266;
      display: flex;  
      align-items: center;
    }

    .arco-descriptions-row {
      padding: 5px 0;
    }

    .arco-descriptions-item-value-block {
      padding: 0;
      font-size: 14px;
      color: #86909c;
      line-height: 36px;
    }

    .arco-descriptions-item-label-block {
      line-height: 36px;
    }

    .arco-descriptions-row {
      line-height: 36px;
      height: 36px;
      padding: 0;
    }

    .button {
      padding: 0 10px;

      .arco-btn {
        width: 140px;
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        font-weight: 700;
        border-radius: 4px;
        margin-right: 15px;
      }

      .arco-btn-secondary {
        background: #e0f4f0;
        color: #05a081;

        &:hover {
          background: #DCFAF4;
        }
      }
    }
  }
</style>